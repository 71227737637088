"use client";

import { usePathname } from "next/navigation";
import { StyledLink } from "~/components/ui/styled-link";
import { NavBarItem } from "./nav-bar-item";
interface NavBarItemListProps {
  indicatorPosition: "top" | "bottom";
  size: "sm" | "base";
}
export const NavBarItemList = ({
  indicatorPosition,
  size
}: NavBarItemListProps) => {
  const currentPath = usePathname();
  return <>
      <StyledLink href="/home" className="outline-offset-[-1px] outline-primary" data-sentry-element="StyledLink" data-sentry-source-file="nav-bar-item-list.tsx">
        <NavBarItem indicatorPosition={indicatorPosition} size={size} icon="Home" text="Übersicht" active={currentPath === "/home"} data-sentry-element="NavBarItem" data-sentry-source-file="nav-bar-item-list.tsx" />
      </StyledLink>

      <StyledLink href="/projects" className="outline-offset-[-1px] outline-primary" data-sentry-element="StyledLink" data-sentry-source-file="nav-bar-item-list.tsx">
        <NavBarItem indicatorPosition={indicatorPosition} size={size} icon="Squares2X2" text="Maßnahmen" active={currentPath === "/projects"} data-sentry-element="NavBarItem" data-sentry-source-file="nav-bar-item-list.tsx" />
      </StyledLink>

      {/* <StyledLink
        href="/data"
        className="outline-offset-[-1px] outline-primary"
       >
        <NavBarItem
          indicatorPosition={indicatorPosition}
          size={size}
          icon="ChartBar"
          text="Daten"
          active={currentPath === "/data"}
        />
       </StyledLink> 
       <StyledLink
        href="/discussions"
        className="outline-offset-[-1px] outline-primary"
       >
        <NavBarItem
          indicatorPosition={indicatorPosition}
          size={size}
          icon="ChatBubbleLeft"
          text="Diskussion"
          active={currentPath === "/discussions"}
        />
       </StyledLink> */}

      <StyledLink href="/events" className="outline-offset-[-1px] outline-primary" data-sentry-element="StyledLink" data-sentry-source-file="nav-bar-item-list.tsx">
        <NavBarItem indicatorPosition={indicatorPosition} size={size} icon="Calendar" text="Events" active={currentPath === "/events"} data-sentry-element="NavBarItem" data-sentry-source-file="nav-bar-item-list.tsx" />
      </StyledLink>
    </>;
};