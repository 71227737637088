"use client";

import { Button, type ButtonProps } from "~/components/ui/button";
import { StyledLink } from "~/components/ui/styled-link";
interface ButtonBackProps extends ButtonProps {
  children?: React.ReactNode;
  redirect?: string;
}
export const ButtonSignin: React.FC<ButtonBackProps> = ({
  children,
  ...props
}) => {
  return <Button {...props} type="submit" asChild={true} data-sentry-element="Button" data-sentry-component="ButtonSignin" data-sentry-source-file="button-signin.tsx">
      <StyledLink href={`/signin${props.redirect ? `?redirect=${props.redirect}` : ""}`} data-sentry-element="StyledLink" data-sentry-source-file="button-signin.tsx">
        {children}
      </StyledLink>
    </Button>;
};